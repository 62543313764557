import { TraderSearchType } from './components/tables/table.model';
import { TimePeriod, TimeRangeOptions } from './enums/market-inspection.enum';
import {
    OneDayMilliseconds,
    OneHourMilliseconds,
    OneMinuteMilliseconds,
    OrderStatus,
    PackageType
} from './models/market-inspection.models';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { AlertHeaderName, TableHeader } from './components/tables/table-columns.model';
import { ChartInterval } from './enums/chart.enum';
import { IChartPeriodicity } from './models/chart.models';
import { IEventColorConfig } from './models/events.models';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { colorSchemeDark, iconSetMaterial, themeQuartz } from 'ag-grid-enterprise';

export const PRIVATE_EXCHANGE = 'PRIVATE';

export const NOT_IN_USE_PLACEHOLDER = 'not_in_use';

export const DEFAULT_EXCHANGE_PLACEHOLDER = 'Default';
export const MULTIPLE_TITLE = 'Multiple';
export const NONE = 'None';

export const MARKER_OFFSET = 7; // px
export const MARKER_IMAGE_SIZE = 14; // px

export const MAX_MARKER_SIZE_PX = 16; // px
export const XL_MARKER_SIZE_PX = 24; // px
export const MIN_MARKER_SIZE_PX = 4; // px
export const CLIENT_SEARCH_MAX_LENGHT = 5;
export const CLIENT_ID_SEARCH_LIMIT = 25;
export const MI_DOWLOAD_EVENTS_LIMIT = 1_000_000;
export const MI_TABLE_PAGE_SIZE_OPTIONS = [20, 50, 100];
export const MI_TABLE_DEFAULT_SIZE = MI_TABLE_PAGE_SIZE_OPTIONS[0];
export const MI_TABLE_DEFAULT_SORT = 'transactionTime,desc';
export const MI_TABLE_ALERT_RELATED_SORT = 'isAlertRelated,desc';
export const MI_ALERT_TABLE_DEFAULT_SORT = 'id,desc';
export const MI_ALERT_TABLE_FROM_TIME_SORT = 'fromTime,asc';
export const MI_MAX_CHART_PERIOD_DAYS = 180;
export const MI_MAX_CHART_CSV_PERIOD_DAYS = 365;

export const MAX_FILE_ROWS = 10_000;

export const MAX_ITEMS_PER_SELECTION = 3;
export const MAX_EX_VENUES_PER_SELECTION = 3;

export const MESSAGE_DURATION_TIME = 8_000;

export const ENABLED_EXCHANGES = [PRIVATE_EXCHANGE, 'COINBASE', 'BINANCE'];

export const BBO_PRIMARY_PUBLIC_EXCHANGES = ['COINBASE', 'BINANCE'];

export const DISABLED_EXCHANGES = ['CCCAGG', 'CCAGG'];

export const EXECUTION_STATUS = [OrderStatus.filled, OrderStatus.partiallyfilled];

export const COMMON_SYMBOLS = ['BTC/USD', 'BTC/USDT', 'ETH/USD', 'ETH/USDT', 'USDT/USD', 'USDC/USD'];

export const PREMIUM_PACKAGE_SYMBOLS = ['UNISWAP'];

export const DEFAULT_TIME_RANGE_OPTION = TimeRangeOptions.day_5;
export const DEFAULT_TIME_PERIOD = TimePeriod.min_10;

export const DEFAULT_TRADER_SEARCH_TYPE = TraderSearchType.clientId;

export const DEFAULT_EVENTS_COLOR_CONFIG: IEventColorConfig = {
    alert: {
        isButton: false,
        tooltip: { on: 'Alert time view On', off: 'Alert time view Off' },
        active: false
    }
    // todo: fix when refactor events-description code
    // buy: {
    //     isButton: false
    // },
    // sell: {
    //     isButton: false
    // }
};

export const UNRESOLVED_CLIENT_ID_VALUES = ['N/A'];

export const MI_MAX_SINGLE_EVENTS = 5_000; // number of Events at which Single events mode is not allowed
export const MI_USE_ONLY_EXECUTIONS_LIMIT = 1_000_000; // number of Events at which aggregation events will be switched to 'Executions Only' mode
export const MI_AUTO_EVENTS_MODE_CHANGE_THRESHOLD = 500; // number of Events at which Single events mode will be switched to Aggregation mode (of opposite)
export const MIN_CANDLE_AMOUNT_ALERT_VIEW = 20; // for pretty showing short periods on the chart need to add at least a minimum number of candles to the Alert view
export const ADDITIONAL_TIME_CSV_VIEW = OneHourMilliseconds; // for pretty showing short periods on the chart this period will be added to CSV view

export const MI_TRADERS_EVENTS_LIMIT = 1000;
export const MI_NEWS_EVENTS_LIMIT = 500;

export const ALERT_CACHE_TTL = 2 * OneHourMilliseconds;
export const SCREEN_CONFIGURATION_CACHE = 30 * OneDayMilliseconds;

export const ALERT_SWIM_LANE_TITLE = 'Alerts';
export const ALERT_SWIM_LANE_STATS_TITLE = 'Alert Statistics';
export const MI_SWIM_LANE_PANEL_NAME = 'Alerts';

export const DEFAULT_STORAGE_TTL = 30 * OneMinuteMilliseconds;

export const MAX_SWIMLANE_LABLE_LENGHT = 11;
export const HORIZONTAL_ELLIPSIS = '…';

export const MI_MAX_CUSTOM_TIME_RANGE_MS = OneDayMilliseconds * 30 - 1;
export const MI_MAX_CUSTOM_TIME_RANGE_MS_STANDARD_PACKAGE = OneDayMilliseconds * 5 - 1;

export const USE_LOCAL_TIME = false;

export const MIN_CUSTOM_TIME_RANGE_PERIOD_MIN = 1;
export const CROSS_PROD_Y_AXIS_MARGIN_PERCENT = 5;

export const ALL_TIME_RANGE_OPTIONS_DISABLED_RULES = {
    [TimeRangeOptions.min_30]: true,
    [TimeRangeOptions.day_1]: true,
    [TimeRangeOptions.day_5]: true,
    [TimeRangeOptions.month_1]: true,
    [TimeRangeOptions.month_3]: true,
    [TimeRangeOptions.month_6]: true
};

export const ALL_TIME_RANGE_OPTIONS_ENABLED_RULES = {
    [TimeRangeOptions.min_30]: false,
    [TimeRangeOptions.day_1]: false,
    [TimeRangeOptions.day_5]: false,
    [TimeRangeOptions.month_1]: false,
    [TimeRangeOptions.month_3]: false,
    [TimeRangeOptions.month_6]: false
};

export const STANDARD_PACKAGE_TIME_RANGE_OPTIONS_RULES = {
    [TimeRangeOptions.min_30]: false,
    [TimeRangeOptions.day_1]: false,
    [TimeRangeOptions.day_5]: false,
    [TimeRangeOptions.month_1]: true,
    [TimeRangeOptions.month_3]: true,
    [TimeRangeOptions.month_6]: true
};

export const DEFAULT_TIME_PERIOD_BY_TIME_RANGE = {
    [TimeRangeOptions.min_30]: TimePeriod.sec_1,
    [TimeRangeOptions.day_1]: TimePeriod.min_1,
    [TimeRangeOptions.day_5]: TimePeriod.min_10,
    [TimeRangeOptions.month_1]: TimePeriod.hour_1,
    [TimeRangeOptions.month_3]: TimePeriod.hour_6,
    [TimeRangeOptions.month_6]: TimePeriod.day_1
};

export const UNSUPPORTED_ALERT_TYPES = [
    'DEX_INSIDER_TRADING_MONITORED_WALLET',
    'DEX_INSIDER_TRADING_B_MONITORED_WALLET',
    'DEX_INSIDER_TRADING_S_MONITORED_WALLET',
    'DEX_INSIDER_TRADING_BAS_MONITORED_WALLET',
    'DEX_INSIDER_TRADING',
    'DEX_INSIDER_TRADING_B',
    'DEX_INSIDER_TRADING_S',
    'DEX_INSIDER_TRADING_BAS'
];

export const MI_MAX_PAGINATION_RANGE_FROM_CUSTOM_PERIOD = 90 * OneDayMilliseconds; // sle-14254: if custom timerange is selected, user will be able to scroll left or right or zoom out, Increase scrolling to 3 mounths
export const MI_MAX_PAGINATION_RANGE = 4; // +1 current range = 5

export const ALL_BE_ALERT_SUPPORTED_TABLE_COLUMNS: AlertHeaderName[] = [
    AlertHeaderName.Id,
    AlertHeaderName.CaseId,
    AlertHeaderName.CreatedAt,
    AlertHeaderName.FromTime,
    AlertHeaderName.ToTime,
    AlertHeaderName.AlertType,
    AlertHeaderName.CaseStatus,
    AlertHeaderName.Score,
    AlertHeaderName.ManipulationValue,
    AlertHeaderName.ThresholdBreach,
    AlertHeaderName.ThresholdValue,
    AlertHeaderName.Symbols,
    AlertHeaderName.Accounts,
    AlertHeaderName.Actors,
    AlertHeaderName.Clients
];

const DERIVATIVES_TABLE_COLUMNS: TableHeader[] = [
    TableHeader.securityType,
    TableHeader.exchangeSymbol,
    TableHeader.expirationDateTime,
    TableHeader.settleDateTime,
    TableHeader.positionEffect,
    TableHeader.putCall,
    TableHeader.strikePrice,
    TableHeader.fundingRate,
    TableHeader.contractMultiplier,
    TableHeader.cfiCode,
    TableHeader.exerciseStyle,
    TableHeader.strikeValue
];

export const getAllBeSupportedTableColumns = (isDerivatives: boolean): TableHeader[] => {
    const tableColumns = [
        TableHeader.transactionTime,
        TableHeader.eventId,
        TableHeader.isExecution,
        TableHeader.clientId,
        TableHeader.account,
        TableHeader.actorId,
        TableHeader.matchingAccountId,
        TableHeader.matchingClientId,
        TableHeader.matchingActorId,
        TableHeader.symbol,
        TableHeader.side,
        TableHeader.matchingOrderId,
        TableHeader.orderType,
        TableHeader.orderQty,
        TableHeader.orderCapacity,
        TableHeader.orderStatus,
        TableHeader.quantity,
        TableHeader.expirationDateTime,
        TableHeader.orderPrice,
        TableHeader.execPrice,
        TableHeader.trdType,
        TableHeader.executionType,
        TableHeader.cumQty,
        TableHeader.contingencyType,
        TableHeader.timeInForce,
        TableHeader.origTransactTime,
        TableHeader.leavesQty,
        TableHeader.buIdentifier,
        TableHeader.matchingExecutionId,
        TableHeader.stopPx,
        TableHeader.leavesQty,
        TableHeader.ipAddress,
        TableHeader.matchingIpAddress,
        TableHeader.localNotional,
        TableHeader.localCurrency
    ];

    if (isDerivatives) {
        tableColumns.push(...DERIVATIVES_TABLE_COLUMNS);
    }
    return tableColumns;
};

export const MI_MIN_INTERVALS_SIZE = 5;

export const CONNECTED_POSITION: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
};

export function isExchangeEnabledFn(
    exchange: string,
    isExchangeEnabledFeatureFlag: boolean,
    isCrossProduct?: boolean
): boolean {
    if (!isExchangeEnabledFeatureFlag || isCrossProduct) {
        return true;
    }
    return IS_PRIVATE_EXCHANGE(exchange) || ENABLED_EXCHANGES.includes(exchange.toUpperCase());
}

export const IS_PRIVATE_EXCHANGE = (val): boolean => val.startsWith(PRIVATE_EXCHANGE);

export function filterDisabledExchanges(exchanges: string[]): string[] {
    return exchanges.filter(exchange => !DISABLED_EXCHANGES.includes(exchange.toUpperCase()));
}

export function filterExchangeByPackageType(packageType: PackageType, exchanges: string[]): string[] {
    if (packageType !== PackageType.PREMIUM) {
        return exchanges.filter(exchange => !PREMIUM_PACKAGE_SYMBOLS.includes(exchange.toUpperCase()));
    } else {
        return exchanges;
    }
}

export const CHIP_COLOR_MAP = [
    '#5CA6C2',
    '#B070FF',
    '#9DAF5A',
    '#AC6C9E',
    '#4941D8',
    '#A331AB',
    '#E1718B',
    '#D4C856',
    '#9E8AED',
    '#4373AA'
];

export const ALERT_TABLE_PARAMS = {
    sort: MI_ALERT_TABLE_DEFAULT_SORT,
    page: 0,
    size: MI_TABLE_DEFAULT_SIZE
};

export const ALERT_TBL_FIELD_MAPPER = {
    [TableHeader.clientId]: AlertHeaderName.Clients,
    [TableHeader.account]: AlertHeaderName.Accounts,
    [TableHeader.actorId]: AlertHeaderName.Actors
};

/*

    Documentation:
    https://documentation.chartiq.com/CIQ.ChartEngine.html#setPeriodicity

    Examples:
    // each bar on the screen will represent 15 minutes (combining 15 1-minute bars from your server)
    {period:15, timeUnit:"minute"}
    // each bar on the screen will represent 15 minutes (a single 15 minute bar from your server)
    {period:1, timeUnit:"minute", interval:15}
    // each bar on the screen will represent 30 minutes formed by combining two 15-minute bars; each masterData element represening 15 minutes.
    {period:2, timeUnit:"minute", interval:15}
*/

export const SMART_ZOOM_PERIODICITIES: { [key: string]: IChartPeriodicity } = {
    [TimePeriod.ms_1]: { period: 1, interval: 1, timeUnit: ChartInterval.millisecond }, // 1ms candle from BE
    [TimePeriod.ms_10]: { period: 1, interval: 10, timeUnit: ChartInterval.millisecond }, // 10ms candle from BE
    [TimePeriod.ms_100]: { period: 1, interval: 100, timeUnit: ChartInterval.millisecond }, // 100ms candle from BE
    [TimePeriod.sec_1]: { period: 1, interval: 1, timeUnit: ChartInterval.second }, // 1s candle from BE
    [TimePeriod.sec_10]: { period: 1, interval: 10, timeUnit: ChartInterval.second }, // 10s candle from BE
    [TimePeriod.min_1]: { period: 1, interval: 1, timeUnit: ChartInterval.minute }, // 1m candle from 1BE
    [TimePeriod.min_10]: { period: 1, interval: 10, timeUnit: ChartInterval.minute }, // 10m candle from BE
    [TimePeriod.hour_1]: { period: 1, interval: 60, timeUnit: ChartInterval.minute }, // 1h candle from BE
    [TimePeriod.hour_6]: { period: 1, interval: 360, timeUnit: ChartInterval.minute }, // 6h candle from BE
    [TimePeriod.day_1]: { period: 1, interval: 1, timeUnit: ChartInterval.day } // 1d candle from BE
};

export const SMART_ZOOM_BOUNDARIES = {
    // Zoom-in
    maxCandleWidth: 50,
    minTicks: 30,
    // Zoom-out
    minCandleWidth: 4,
    maxTicks: 350
};

export const SMART_ZOOM_BOUNDARIES_SMALL_SCREEN = {
    // Zoom-in
    maxCandleWidth: 50,
    minTicks: 6,
    // Zoom-out
    minCandleWidth: 3,
    maxTicks: 150
};

export const MAX_SMART_ZOOM_CANDLES_AMOUNT = 300;
export const SMART_ZOOM_SMALL_SCREEN = 1340;
export const MI_INIT_SCREEN_ADDITIONAL_CANDLES = 200;
export const MI_CHART_TOP_MARGIN = 30;
export const MI_CHART_BOTTOM_MARGIN = 20;

export enum EXTRA_AGGREGATION_MODE {
    none = 1,
    S = 2,
    M = 4,
    L = 8
}

export const EXTRA_AGGREGATION_MODE_CANDLE_WIDTH_PX = {
    [EXTRA_AGGREGATION_MODE.S]: 30,
    [EXTRA_AGGREGATION_MODE.M]: 20,
    [EXTRA_AGGREGATION_MODE.L]: 10
};

export const EXTRA_AGGREGATION_MODE_CANDLE_WIDTH_PX_SMART_AGGREGATION = {
    [EXTRA_AGGREGATION_MODE.S]: 16,
    [EXTRA_AGGREGATION_MODE.M]: 10,
    [EXTRA_AGGREGATION_MODE.L]: 5
};

export const MAX_SECONDARY_SYMBOLS_SELECT = 2;

export const TIPPY_PROPS_SKIP_EMPTY_TOOLTIP: NgxTippyProps = {
    onShow: instance => {
        if (instance.props.content === null || instance.props.content['length'] === 0) {
            return false;
        }
    }
};

export const ADVANCED_SEARCH_URL: string = '/advanced-search';
export const MAX_EXCHANGE_LENGTH: number = 20; // use to cut free text exchanges / exVenues names
export const ORDER_BASED_ALERTS_PREFIX_LIST: string[] = [
    'LAYERING',
    'QUOTE_STUFFING',
    'EXCESSIVE_MESSAGING',
    'SIMILAR_ORDER'
];

export const agGridTheme = themeQuartz.withPart(iconSetMaterial).withPart(colorSchemeDark).withParams({
    backgroundColor: '#151515'
});
